/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../styles/global.scss';
import 'bootstrap';
import {polyfillCountryFlagEmojis} from "country-flag-emoji-polyfill";


// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
global.$ = global.jQuery = require('jquery');
var resizeTO;

polyfillCountryFlagEmojis();

$(document).ready(function() {

    $('[data-toggle="tooltip"]').tooltip();

    var body = $('body');

    body.on('click', '.disable-on-click', function(event) {
        if($(this).hasClass('disabled'))
        {
            event.preventDefault();
        } else
        {
            $(this).html('<i class="fa fa-spin fa-spinner"></i> ' + $(this).html());
            $(this).addClass('disabled');

            setTimeout(function() {
                $('.disable-on-click').removeClass('disabled');
                $('.disable-on-click').find('i.fa-spin').remove();
            }, 5000);
        }
    });

    body.on('click', '.btn-copy-to-clipboard', async function(event) {
        try
        {
            await navigator.clipboard.writeText($(this).attr('data-clipboard-text'));
            $(this).attr("title", "Copied!").tooltip("_fixTitle").tooltip("show").attr("title", "Copy to clipboard").tooltip("_fixTitle");
        } catch(err)
        {
            console.error('Failed to copy: ', err);
        }
    });

    body.on('mouseleave', '.btn-copy-to-clipboard', function(event) {
        $(this).tooltip('hide');
    });


    $(window).scroll(function() {
        if($(this).scrollTop() > 50)
        {
            $('#back-to-top').fadeIn();
        } else
        {
            $('#back-to-top').fadeOut();
        }
    });

    $('#back-to-top').click(function() {
        $('body,html').animate({
            scrollTop: 0
        }, 400);
        return false;
    });

    $('.demo-vat-check-json').on('click', function(event) {
        event.preventDefault();
        var $this = $(this);
        var target= $this.attr('href');
        var result = $('.demo-vat-check-json-result');

        result.html('<i class="fa fa-spin fa-spinner" style="font-size: 4rem;"></i> ');
        // post data
        var data = {
            vatNumber: $this.attr('data-vat-id'),
        };

        $this.siblings().removeClass('badge-primary').addClass('badge-light');
        $this.removeClass('badge-light').addClass('badge-primary');

        $.ajax({
            url: target,
            type: 'POST',
            data: data,
            dataType: 'html',
            success: function(response) {
                result.html(response);
            },
            error: function(response) {
                result.html('');
            },
            complete: function(response) {
            }
        });
    });

});
